.App {
  text-align: center;
}

body {
  overflow: hidden;
}

.vjs-current-time { display: block !important; }
.vjs-duration { display: block !important; }
.vjs-time-divider { display: block !important; }
.vjs-remaining-time  { display: none !important; }
.vjs-poster { background-color: #f4f9ff !important}

li.vjs-menu-item {
  text-transform: capitalize !important;
}

.vjs-texttrack-settings {
  display: none !important;
}

.vjs-text-track-cue {
  opacity: 0.5;
}

/*
.vjs-big-play-button { 
  background: linear-gradient(180deg,#4dc2ff,#0798e3) !important;
  border: transparent !important;
  border-radius: 126px !important;
  box-shadow: 0 2px 0 #1583bb !important;
  color: #fff !important;
  cursor: pointer !important;
}

.vjs-big-play-button:hover { 
  background: linear-gradient(0deg ,#4dc2ff,#0798e3) !important; 
  color: #fff !important;
}

*/